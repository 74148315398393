.fxc-container {
  width: 100%;
  padding-bottom: 80px;

  &-wrapper {
    width: 100%;
    max-width: 1280px;
    margin: 0px auto;
    padding: 10px 60px;
    margin-top: 50px;

    &-heading {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      h1::after {
        content: "";
        bottom: 0;
        background: #eeb856;
        width: 30%;
        height: 4px;
        position: absolute;
        display: block;
        left: 35%;
      }

      h1 {
        font-size: 34px;
        font-weight: bold;
        line-height: 50px;
        font-family: Montserrat;
        width: 50%;
        color: #0a1b28;
        text-align: center;
        padding-bottom: 20px;
      }
    }

    &-quote {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 50px;
      background-color: #f6f3f1;
      padding: 20px;
      align-items: center;
      border-radius: 5px;

      &-commaOne {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        span {
          font-size: 63px;
          font-weight: bold;
          line-height: 50px;
          font-family: Montserrat;

          color: #eeb856;
        }
      }

      &-para {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 65%;

        p {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          font-family: Montserrat;
          color: #0a1b28;
        }
      }

      &-commaTwo {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        span {
          font-size: 63px;
          font-weight: bold;
          line-height: 50px;
          font-family: Montserrat;

          color: #eeb856;
          transform: rotate(180deg);
        }
      }
    }

    &-divset {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-profileimages {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      flex-direction: column;
      gap: 30px;

      &-pfone {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;

        img {
          width: 38px;
        }

        &-heading {
          display: flex;
          flex-direction: column;

          span {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            font-family: Montserrat;
          }

          h6 {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            font-family: Montserrat;
          }
        }
      }

      &-quotesdiv {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-divone {
          width: 30%;
          // background-color:#F6F3F1 ;

          &-imgmargin {
            margin-left: 40px;
          }
          &-proset {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
          }

          &-imgset {
            border-radius: 5px;
            width: 100%;
            height: 200px;
            background-color: #f6f3f1;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            p {
              width: 100%;
              text-align: center;
              font-size: 14px;
              font-weight: 500;
              line-height: 24px;
              font-family: Montserrat;
            }
          }
        }
      }
    }

    &-btn {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 100px;

      button {
        background-color: #eeb856;
        color: #000000;
        padding: 10px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: bold;
        padding-left: 2rem;
        padding-right: 2rem;
        border-radius: 5px;
        border: none;
      }
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .fxc-container {
    &-wrapper {
      width: 100%;
      padding: 30px;
    }
  }
}

@media only screen and (max-width: 768px) and (min-width: 428px) {
  .fxc-container {
    &-wrapper {
      width: 100%;
      padding: 20px;
    }
  }
}

@media only screen and (max-width: 428px) and (min-width: 280px) {
  .fxc-container {
    &-wrapper {
      width: 100%;
      padding: 20px;

      &-heading {
        h1 {
          font-size: 25px;
          width: 100%;
          line-height: 35px;
        }
      }

      &-profileimages {
        &-quotesdiv {
          flex-direction: column;

          &-divone {
            width: 100%;

            &-proset {
              margin-bottom: 10px;
            }

            &-imgset {
              p {
                width: 90%;
              }
            }
          }
        }
      }

      &-btn {
        margin-top: 50px;
      }
    }
  }
}
