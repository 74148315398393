.template-four-container {
  width: 90%;
  background-color: white;
  height: fit-content;
  padding: 20px;

  &-wrapper {
    display: flex;
    width: 100%;
    &-divOne {
      width: 40%;
      display: flex;

      align-items: center;
      background-color: #ee6e4a;

      height: 8rem;
      border-top-left-radius: 120px;
      border-bottom-left-radius: 120px;

      &-circle {
        width: 60%;
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: white;

        margin-left: 12px;

        h2 {
          font-size: 50px;
          font-family: Arial;
        }
      }
    }

    &-divTwo {
      width: 60%;
      background-color: #ee6e4a;
      display: flex;
      align-items: center;

      h2 {
        font-size: 50px;
        font-family: Arial;
        word-break: break-word;
      }
    }
  }

  &-body {
    margin-top: 25px;
    width: 100%;
    display: flex;
    gap: 15px;

    &-divOne {
      width: 40%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      h1 {
        font-family: Arial;
        font-size: 17px;
        font-weight: bold;
      }

      p {
        font-size: 14px;
        font-family: Arial;
      }

      &-info {
        display: flex;
        align-items: center;
        gap: 2px;

        span {
          font-family: Arail;
          font-size: 15px;
          color: black;
        }

        h3 {
          font-family: Arail;
          font-size: 15px;
          font-weight: 400;
          color: black;
          word-break: break-word;
        }
      }
    }

    &-divTwo {
      width: 60%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      h1 {
        font-family: Arial;
        font-size: 17px;
        font-weight: bold;
      }

      p {
        font-size: 14px;
        font-family: Arial;
      }
    }
  }

  &-content {
    width: 100%;
    display: flex;
    margin-top: 45px;
    // gap:15px;
    justify-content: space-between;
    padding-bottom: 30px;

    &-education {
      width: 35%;
      display: flex;
      flex-direction: column;

      h1 {
        font-family: Arial;
        font-size: 17px;
        font-weight: bold;
      }

      &-uni {
        margin-top: 10px;
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 2px;

        span {
          font-size: 14px;
          font-family: Arial;
          font-weight: 600;
        }

        p {
          margin-top: 5px;
          font-size: 14px;
          font-family: Arial;
          font-weight: 500;
        }
      }

      &-skills {
        margin-top: 25px;
        display: flex;
        flex-direction: column;

        &-percentage {
          margin-top: 10px;
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;

          h2 {
            font-size: 11px;
            font-family: Arial;
            font-weight: 600;
            word-break: break-word;
          }
        }
      }
    }

    &-parentdiv {
      width: 60%;
      display: flex;
      flex-direction: column;
      // gap:15px;

      &-workexp {
        width: 100%;
        display: flex;
        flex-direction: column;
        word-break: break-word;

        h1 {
          font-family: Arial;
          font-size: 17px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        &-head {
          width: 100%;
          display: flex;

          &-heading {
            display: flex;
            width: 30%;
            flex-direction: column;
            padding-right: 10px;
            gap: 15px;

            h1 {
              font-size: 16px;
              font-family: Arial;
              font-weight: 600;
            }

            h2 {
              display: flex;
              justify-content: flex-end;
              font-size: 15px;
              font-family: Arial;
              font-weight: 600;
            }
          }

          &-info {
            width: 70%;
            padding-left: 8px;
            display: flex;
            flex-direction: column;
            border-left: 1px solid;
            gap: 8px;

            h2 {
              font-size: 15px;
              font-family: Arial;
              font-weight: 600;
            }

            h3 {
              font-size: 14px;
              font-family: Arial;
              font-weight: 600;
            }

            ul {
              list-style: none;
              // margin-left: 5px;

              li::before {
                content: "•";
                color: black;
                margin-right: 3px;
                width: 1em;
              }

              li {
                font-family: Arial;
                font-size: 13px;
                font-weight: 500;
                color: black;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 320px) and (min-width: 280px) {
  .template-four-container-body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
  }
  .template-four-container-body-divOne {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .template-four-container-body-divTwo {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .template-four-container-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .template-four-container-content-education {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .template-four-container-content-education-skills {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .template-four-container-content-education-skills-percentage {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .template-four-container-content-education-uni {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .public-DraftEditorPlaceholder-inner {
    width: 100%;
  }
  .template-four-container-content-parentdiv-workexp {
    flex-wrap: wrap;
  }
  .template-four-container-content-parentdiv-workexp-head {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .template-four-container-content-parentdiv-workexp-head-heading {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .template-four-container-content-parentdiv-workexp-head-info {
    width: 100%;
  }
  .template-four-container-content-parentdiv {
    width: 100%;
  }
}

@media only screen and (max-width: 428px) and (min-width: 375px) {
  .template-four-container-content-parentdiv-workexp-head-heading {
    width: 40%;
  }
  .template-four-container-content-education {
    width: 50%;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 1024px) {
  .generator-container-right {
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-top: 0rem;
  }
}

.gdpr-image {
  display: flex;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  color: #0a1b28;
  justify-content: center;
  align-items: center;
  width: 51%;
}

.template-fourteen-container-right-content-element-reference {
  display: flex;
  justify-content: center;
  align-items: center;
}
