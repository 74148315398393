.footer-container {
  width: 100%;
  background-color: #0a1b28;

  &-wrapper {
    width: 100%;
    max-width: 1280px;
    margin: 0px auto;
    padding: 10px 60px;
    display: flex;
    justify-content: space-around;

    &-logodiv {
      width: 10rem;
      height: 3rem;
      img {
        width: 100%;
        height: 100%;
      }
    }

    &-headinglinks {
      display: flex;
      flex-direction: column;
      gap: 10px;

      h1 {
        font-family: Montserrat;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
      }

      &-link {
        display: flex;
        flex-direction: column;
        gap: 10px;

        span {
          font-family: Montserrat;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }
      }
    }
  }
  &-cc {
    width: 100%;
    max-width: 1280px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 0.1px groove white;
    padding-top: 20px;
    padding-bottom: 20px;

    h2 {
      font-family: Montserrat;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .footer-container {
    &-wrapper {
      width: 100%;
      max-width: 1024px;
      padding: 30px;
    }

    &-cc {
      width: 70%;
      padding: 30px;
    }
  }
}

@media only screen and (max-width: 768px) and (min-width: 428px) {
  .footer-container {
    &-wrapper {
      width: 100%;
      padding: 20px;
    }

    &-cc {
      width: 70%;
      padding: 30px;
    }
  }
}
