.header-container {
  width: 100%;
  background-color: #f6f3f1;

  &-wrapper {
    width: 100%;
    max-width: 1280px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;

    &-logodiv {
      // border: 1px solid red;
      width: 10rem;
      height: 3rem;
      img {
        width: 100%;
        height: 100%;
      }
    }

    &-navlinks {
      width: 55%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 16px;
        font-family: Montserrat;
        font-weight: 500;
      }

      button {
        background-color: #eeb856;
        color: #000000;
        padding: 10px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: bold;
        padding-left: 2rem;
        padding-right: 2rem;
        border-radius: 5px;
        border: none;
      }

      &-withicon {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        span {
          font-size: 16px;
          font-family: Montserrat;
          font-weight: 500;
        }
      }
    }
  }
}

.submenu-container {
  width: 100%;
  background-color: white;
  position: absolute;
  z-index: 9999;
  transition: 0.5s;

  &-wrapper {
    width: 1280px;
    margin: 0px auto;
    display: flex;

    &-items {
      display: flex;
      flex-wrap: wrap;
      width: 60%;
      gap: 20px;
      padding: 20px;

      .cvmaker-body-container-wrapper-maindiv-set {
        width: 30%;
      }
    }

    &-sectwo {
      width: 40%;
      display: flex;
      align-items: flex-end;
      padding: 20px;

      &-imgwithcontent {
        display: flex;
        align-items: center;
        gap: 5px;

        h2 {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
        }

        &-imgset {
          transform: rotate(270deg);
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .submenu-container {
    &-wrapper {
      width: 100%;

      &-items {
        width: 80%;
      }

      &-sectwo {
        width: 20%;
      }
    }
  }

  .header-container {
    &-wrapper {
      width: 1024px;
      padding: 30px;

      &-navlinks {
        width: 65%;
      }
    }
  }
}

// @media only screen and (max-width: 768px) and (min-width: 428px) {

//   .submenu-container {
//     &-wrapper {
//       width: 100%;

//       &-items {
//         width: 80%;
//       }

//       &-sectwo {
//         width: 20%;
//       }
//      }
//   }

//   .header-container {

//      &-wrapper {
//          width:100%;
//          padding: 20px;

//          &-navlinks {
//            width:65%;
//          }

//      }
//   }
// }
