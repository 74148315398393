.template-five-container {
  width: 90%;
  display: flex;
  padding: 25px;
  height: fit-content;
  flex-direction: column;
  background-color: white;

  margin-top: 3rem;

  &-mainheading {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px;
    // border-bottom: 0.5px solid rgba(191, 191, 191, 0.543);
    align-items: center;

    &-subheading {
      display: flex;
      gap: 2rem;
      margin-top: 1rem;
      margin-bottom: 1rem;

      span {
        font-family: Oswald;
        font-size: 15px;
        color: #808080;
        font-weight: 400;
        font-weight: bold;
      }

      span:nth-child(2) {
        font-family: Oswald;
        font-size: 15px;
        color: #808080;
        font-weight: 400;
        font-weight: bold;
        margin-top: 0.5rem;
      }
    }

    h1 {
      font-family: Oswald;
      font-size: 50px;
      color: "black";
    }

    h2 {
      font-family: Oswald;
      font-size: 25px;
      color: #808080;
      font-weight: 400;
    }
  }

  &-wrapper {
    width: 100%;
    display: flex;
    overflow-wrap: break-word;

    &-leftside {
      width: 30%;
      display: flex;
      flex-direction: column;
      position: relative;
      &-heading {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
          font-family: Oswald;
          font-size: 22px;
          color: black;
          font-weight: bold;
          position: relative;
          padding-bottom: 5px;
          align-self: center;
          text-decoration: none;
        }

        &-head {
          margin-bottom: 10px;
          display: flex;
          flex-direction: column;
          // text-align: center;

          h2 {
            font-family: Oswald;
            font-size: 18px;
            color: black;
            font-weight: 600;
            text-transform: uppercase;
            word-break: break-all;

          }

          span {
            font-family: Oswald;
            font-size: 15px;
            font-weight: 400;
            text-align: center;
            word-break: break-all;
          }

          span:nth-child(2) {
            font-family: Oswald;
            font-size: 15px;
            color: black;
            font-weight: 400;
            // margin-left: 2rem;
            margin-top: 10px;
            word-break: break-all;

          }
        }
      }

      &-progress {
        margin-top: 30px;
        display: flex;
        width: 100%;
        flex-direction: column;
        // align-items: center;

        h2 {
          font-family: Oswald;
          letter-spacing: 3px;
          font-size: 22px;
          color: black;
          font-weight: bold;
          position: relative;
          padding-bottom: 5px;
          align-self: center;
          text-decoration: none;
          word-break: break-all;

        }

        .progress-bar {
          margin-top: 15px;

          p {
            font-family: Oswald;
            font-size: 15px;
            color: #000;
            font-weight: bolder;
          }

          &-fuel {
            height: 0.2rem;

            &-wrapper {
              height: 0.2rem;
            }
          }
        }
      }
    }

    &-rightside {
      width: 70%;
      padding: 12px;

      &-wrapper {
        display: flex;
        gap: 1rem;
        width: 90%;
        margin-top: 10px;
        position: relative;

        // div:nth-child(1) {
        //     display: flex;
        //     flex-direction: column;
        //     align-items: center;
        //     overflow-wrap: break-word;
        //     border: 1px solid;
        // }

        div {
          h1 {
            font-family: "Oswald-Bold";
            font-size: 22px;
            color: black;
            font-weight: bold;
            position: relative;
            align-self: center;
            text-decoration: none;
            font-weight: 900;
            word-break: break-all;

          }

          p {
            font-family: Oswald;
            font-size: 15px;
            // color: grey;
            font-weight: 400;
          }
        }

        &-line {
          width: 1px;
          height: 90%;
          background-color: grey;

          &-content22 {
            width: 100%;
            overflow-wrap: break-word;
            flex-direction: column;
          }
        }
      }
    }
  }
}
