.faq-container {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 100px;

  &-wrapper {
    width: 100%;
    max-width: 1280px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 60px;

    &-heading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &:after {
        content: "";
        bottom: 0;
        background: #eeb856;
        width: 20%;
        height: 4px;
        position: absolute;
        display: block;
        left: 38%;
      }

      h1 {
        font-family: Montserrat;
        font-size: 34px;
        font-weight: bold;
        line-height: 50px;
        padding-bottom: 20px;
      }
    }

    &-acc {
      margin-top: 50px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .faq-container {
    &-wrapper {
      width: 100%;
      max-width: 1024px;
      padding: 30px;
    }
  }
}

@media only screen and (max-width: 768px) and (min-width: 428px) {
  .faq-container {
    &-wrapper {
      width: 100%;
      padding: 20px;
    }
  }
}

@media only screen and (max-width: 428px) and (min-width: 375px) {
  .faq-container {
    &-wrapper {
      width: 100%;
      padding: 20px;

      &-heading {
        h1 {
          font-size: 30px;
        }
      }

      .accordian-container {
        padding: 20px;
      }
    }
  }
}

@media only screen and (max-width: 320px) and (min-width: 280px) {
  .faq-container {
    &-wrapper {
      width: 100%;
      padding: 20px;

      &-heading {
        h1 {
          font-size: 25px;
        }
      }

      .accordian-container {
        padding: 10px;

        p {
          font-size: 13px;
        }

        &-headimg {
          h1 {
            font-size: 18px;
          }
        }
      }
    }
  }
}
