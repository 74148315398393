.cv-form {
  width: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: bold;
    line-height: 70px;
    color: #0a1b28;
  }

  &-wrapper {
    width: 100%;
    display: flex;
    gap: 20px;

    &-imgdiv {
      width: 20%;
      display: flex;
      flex-direction: column;
      gap: 5px;

      span {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        color: #0a1b28;
      }

      &-bg {
        height: 9.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f6f3f1;
        border-radius: 5px;
      }
    }

    &-inputheading {
      width: 80%;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  &-numberpost {
    margin-top: 45px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  &-dob {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 5px;
    // margin-top: 15px;
    position: relative;

    span {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      color: #0a1b28;
    }

    input {
      width: 100%;
      background-color: #f6f3f1;
      border-radius: 5px;
      border: none;
      padding: 20px;
      justify-content: flex-start;
      // text-align: start;
      // position: relative;
    }

    &-icon {
      // position: absolute;
      border: 5px solid red;
      width: 100%;
      right: 3%;
      top: 53%;
    }
  }

  &-addmoredetails {
    width: 100%;
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    gap: 5px;

    h2 {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      color: #0a1b28;
    }

    &-detailset {
      margin-top: 10px;
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;

      // &-single-row {
      //     width: 100%;
      // }
    }
  }

  &-study {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 100px;
    gap: 8px;

    h2 {
      font-family: Montserrat;
      font-size: 20px;
      font-weight: bold;
      color: #0a1b28;
    }

    span {
      font-weight: 600;
      font-size: 12px;
      font-family: Montserrat;
      color: #0a1b28;
    }
    &-header-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      h2 {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: bold;
        color: #0a1b28;
      }
      button {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        border-radius: 5px;
        gap: 5px;
        background: #f6f3f1;
        padding: 10px;
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 16px;
        border: 1px solid #f6f3f1;
        background-color: #eeb856;
        margin: 10px;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 768px) {
    .cv-form {
      &-addmoredetails {
        &-detailset {
          overflow-y: scroll;
          flex-wrap: nowrap;
        }
      }
    }

    &-wrapper {
      &-imgdiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;

        span {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 600;
          color: #0a1b28;
        }
      }
    }
  }
}

.printToPdfButton {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  border-radius: 5px;
  gap: 5px;
  background: #f6f3f1;
  padding: 10px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #f6f3f1;
  background-color: #eeb856;
  margin: 10px;
}

@media only screen and (max-width: 1024px) and (min-width: 1024px) {
  .cv-form-wrapper-imgdiv {
    width: 50%;
  }
}

.profile-heading {
  margin-top: 10px;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  color: #0a1b28;
}

.swtichbtnwithpara {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;

  p {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    color: #0a1b28;
  }
}

.react-datepicker {
  display: flex !important;
  flex-direction: column !important;
}
