*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

html {
  scroll-behavior: smooth;
  background-color: #ffffff;
}

$font: 'Montserrat';

@font-face {
  font-family: 'Montserrat';
  src: url('../assests/fonts/Montserrat.ttf');
}
*::-webkit-scrollbar {
}
@font-face {
  font-family: 'Myriad Pro';
  src: url('../assests/fonts/myriad-pro/MYRIADPRO-REGULAR.OTF');
}

ol {
  margin-left: 40px;
}
ul {
  margin-left: 40px;
}

div > ul {
  margin-left: 40px;
}
.template-eight-container-content-right-content {
  font-weight: 300;
}

.preview-button {
  position: fixed;
  display: inline-block;
  top: 50%;
  right: 0;
  button {
    background-color: #eeb856;
    border: none;
    color: black;
    padding: 1em 0.5em;
    border-radius: 0.5rem;
    cursor: pointer;
    font-family: 'Roboto';
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    width: 90%;
    border: 1px solid #fff;
    box-shadow: 2px 2px 3px #999;
  }
}
@media screen and (max-width: 768px) {
  .preview-button {
    right: -10px !important;
    // display: none !important;
    button {
      background-color: #eeb856;
      border: none;
      color: black;
      padding: 1em 0.1em;
      border-radius: 0.5rem;
      cursor: pointer;
      font-family: 'Roboto';
      font-size: 0.8em;
      font-weight: bold;
      text-align: center;
      width: 80%;
    }
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  .modal-content {
    background-color: white;
    padding: 1em;
    border-radius: 0.5rem;
    max-width: 85%;
    width: 90%;
    align-items: center;
    display: flex;
    flex-direction: column;

    .modal-button {
      background-color: #eeb856;
      border: none;
      color: black;
      padding: 1em 0.5em;
      border-radius: 0.5rem;
      cursor: pointer;
      font-family: 'Roboto';
      font-size: 1em;
      font-weight: bold;
      text-align: center;
      width: 20%;
    }
  }
}
/* scrollbar */
::-webkit-scrollbar {
  width: 8px;
}
:-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(246, 243, 241);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  /* background: gray;  */
}
/* end */

/* Modal styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.modalContent {
  background-color: #fff;
  padding: 20px;
  max-width: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
/* end */

.proceed-button {
  display: block;
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 50px;
  right: 10px;
  border-radius: 50px;
  text-align: center;
  background-color: #eeb856;
  z-index: 1;
  border: 1px solid #fff;
  box-shadow: 2px 2px 3px #999;
}
.react-datepicker-wrapper{
  width: 100% !important;
}
