.templateten-container {
  width: 100%;
  display: flex;
  padding: 25px;
  height: fit-content;
  padding-bottom: 10rem;
  flex-direction: column;
  background-color: white;

  &-headingwrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 3px;
      text-align: center;

      h1 {
        font-size: 30px;
        font-family: Myriad Pro;
        font-weight: 900;
        color: black;
        letter-spacing: 3px;
      }

      h2 {
        letter-spacing: 5px;
        font-size: 18px;
        font-family: Myriad Pro;
        font-weight: 600;
        color: black;
      }
    }

    &-info {
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      span {
        font-size: 15px;
        font-family: Myriad Pro;
        color: black;
      }

      div {
        display: flex;
        gap: 2px;
        align-items: center;
      }
    }

    &-mainheading {
      display: flex;
      width: 100%;

      margin-top: 22px;

      flex-direction: column;

      &-borderdiv {
        width: 100%;
        border-bottom: 1px solid black;
        padding-bottom: 2px;
        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
          font-size: 20px;
          font-family: Myriad Pro;
          font-weight: 800;
          color: black;
          letter-spacing: 4px;
        }

        &-studying {
          display: flex;
          padding-top: 10px;
          align-items: center;
          justify-content: center;

          &-head {
            font-weight: 600;
            padding-top: 10px;
            display: flex;
            gap: 10px;
            width: 100%;
            justify-content: center;
            align-items: center;

            &-heading {
              width: 13%;
              display: flex;
              justify-content: flex-end;
              p {
                font-size: 16px;
                font-family: Myriad Pro;
                font-weight: 900;
                color: black;
                word-break: break-word;
                width: 100%;
              }
            }
            p {
              font-size: 14px;
              // font-family: Myriad-Pro-Light;
              font-family: "Myriad Pro";

              color: black;
              word-break: break-all;
              text-align: left;
              font-weight: 400;
              
            }

            &-right {
              display: flex;
              flex-direction: column;
              width: 70%;
              text-align: left;
            }
          }
        }
      }
    }
  }
}

.divsetlangandcourse {
  display: flex;
  width: 70%;
  gap: 11px;
  flex-direction: column;
  p {
    text-align: left;
  }
}

@media only screen and (max-width: 428px) and (min-width: 280px) {
  .templateten-container-headingwrapper-info div {
    display: flex;
    gap: 0px;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .templateten-container-headingwrapper-mainheading-borderdiv-studying-head {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
