.accordian-container {
    width: 100%;
    background-color: #F6F3F1;
    padding: 30px;
    border-radius: 5px;

    p{
        font-family:Montserrat ;
        font-size: 16px;
        font-weight:400;
        line-height: 24px;
        color:#0A1B28;
    }


    &-headimg {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
            font-family:Montserrat ;
            font-size: 20px;
            font-weight:600;
            line-height: 50px;
            color:#0A1B28;
        }
    }
}

.custominputdiv {
    width: 100%;
    background-color: white;
    border-radius: 5px;
    border: none;
    padding: 20px;
    text-align: start;
    display: flex;
    align-items: center;

    label{
        width: 80%;
        font-family: monospace;
    }

    &-iconsdiv {
        width: 20%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }


}