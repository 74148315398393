.primary-button{
    background-color: #eeb856;
    color: #000000;
    padding: 10px;
    font-size: 14px;
    font-family: Montserrat;
    // font-weight: bold;
    font-weight: unset;
    -webkit-text-stroke: thin;
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 5px;
    border: none;
    // -webkit-text-stroke: thin;
}