.cvm-container {
  width: 100%;
  background-color: #f6f3f1;
  padding-top: 100px;
  padding-bottom: 100px;

  &-wrapper {
    width: 100%;
    max-width: 1280px;
    margin: 0px auto;
    display: flex;
    padding: 10px 60px;

    &-heading {
      display: flex;
      width: 50%;
      flex-direction: column;
      gap: 20px;

      h6 {
        font-size: 16px;
        font-family: Montserrat;
        font-weight: bold;
        line-height: 16px;
        color: #eeb856;
        text-transform: uppercase;
      }

      h1 {
        font-size: 34px;
        font-family: Montserrat;
        font-weight: bold;
        line-height: 45px;
        color: #0a1b28;
      }

      span {
        font-size: 18px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 28px;
        color: #0a1b28;
      }

      &-btn {
        width: 85%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        // gap: 50px;

        button:nth-of-type(1) {
          background-color: #eeb856;
          color: #000000;
          padding: 10px;
          font-size: 14px;
          font-family: Montserrat;
          font-weight: bold;
          padding-left: 2rem;
          padding-right: 2rem;
          border-radius: 5px;
          border: none;
        }

        button:nth-of-type(2) {
          background-color: #ffffff;
          color: #000000;
          padding: 10px;
          font-size: 14px;
          font-family: Montserrat;
          font-weight: bold;
          padding-left: 2rem;
          padding-right: 2rem;
          border-radius: 5px;
          border: 1px solid #dbd5d5;
        }
      }
    }

    &-imgdiv {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      h2 {
        font-size: 34px;
        font-family: Montserrat;
        font-weight: bold;
        line-height: 45px;
        color: #0a1b28;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .cvm-container {
    &-wrapper {
      width: 100%;
      max-width: 1024px;
      padding: 30px;
    }
  }
}

@media only screen and (max-width: 768px) and (min-width: 428px) {
  .cvm-container {
    &-wrapper {
      width: 100%;
      padding: 20px;
    }
  }
}

@media only screen and (max-width: 428px) and (min-width: 375px) {
  .cvm-container {
    padding-top: 50px;
    padding-bottom: 50px;

    &-wrapper {
      width: 100%;
      padding: 20px;
      flex-direction: column;
      gap: 60px;

      &-heading {
        width: 100%;

        h1 {
          font-size: 25px;
          line-height: 40px;
        }

        &-btn {
          margin-top: 10px;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
      }

      &-imgdiv {
        width: 100%;

        &-bgSet {
          h2 {
            font-size: 25px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) and (min-width: 320px) {
  .cvm-container {
    padding-top: 50px;
    padding-bottom: 50px;

    &-wrapper {
      width: 100%;
      padding: 20px;
      flex-direction: column;
      gap: 60px;

      &-heading {
        width: 100%;
        h1 {
          font-size: 25px;
          line-height: 40px;
        }

        &-btn {
          margin-top: 10px;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
      }

      &-imgdiv {
        width: 100%;

        &-bgSet {
          h2 {
            font-size: 25px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 320px) and (min-width: 280px) {
  .cvm-container {
    padding-top: 50px;
    padding-bottom: 50px;

    &-wrapper {
      width: 100%;
      padding: 20px;
      flex-direction: column;
      gap: 60px;

      &-heading {
        width: 100%;
        h1 {
          font-size: 25px;
          line-height: 40px;
        }

        &-btn {
          margin-top: 10px;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
      }

      &-imgdiv {
        width: 100%;

        &-bgSet {
          h2 {
            font-size: 25px;
          }
        }
      }
    }
  }
}
