.bloghero-container {
    width: 100%;
    background-color: #F6F3F1;
    padding-top: 100px;
    padding-bottom: 100px;

    &-wrapper {
        width: 100%;
        max-width: 1280px;
        margin: 0px auto;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        h1::after {
            content: "";
            bottom: 0;
            background: #eeb856;
            width: 12%;
            height: 4px;
            position: absolute;
            display: block;
            left: 44%;
          }
        
        h1{
            font-size: 34px;
            line-height: 50px;
            text-align: center;
            font-family: Montserrat;
            font-weight: bold;
            width: 50%;
            padding-bottom: 20px;
        }
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .bloghero-container {

       &-wrapper {
           width:1024px;
           padding: 30px;


       }
    }
}

@media only screen and (max-width: 768px) and (min-width: 428px) {
    .bloghero-container {

       &-wrapper {
           width:100%;
           padding: 30px;


       }
    }
}

@media only screen and (max-width: 428px) and (min-width: 375px) {
    .bloghero-container {

       &-wrapper {
           width:100%;
           padding: 20px;

        h1 {
            width: 100%;
            font-size: 28px;
            padding-bottom: 5px;
        }
       }
    }
}


@media only screen and (max-width: 375px) and (min-width: 280px) {
    .bloghero-container {

       &-wrapper {
           width:100%;
           padding: 20px;

           

        h1 {
            width: 100%;
            font-size: 20px;
             padding-bottom: 5px;
             line-height: 30px;
        }
       }
    }
}