.slider-setting {
  border: 1px solid red;
}

.generator-accordian {
  margin-top: 15px;
  width: 100%;
  background-color: #f6f3f1;
  display: flex;
  flex-direction: column;
  padding: 25px;
  border-radius: 5px;

  &-heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-family: Montserrat;
      font-weight: 600;
      color: #0a1b28;
    }
  }

  &-textfields {
    margin-top: 25px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    .headinput-container {
      span {
        font-weight: 600;
        font-size: 12px;
      }

      input {
        background-color: white;

        // background-color: red;
      }
    }
  }

  &-rangefield {
    margin-top: 15px;
    // padding: 5px 0px 5px 0px;
    width: 50%;
    // -webkit-appearance: none;
    background: #d3d3d3;
    opacity: 0.7;
    transition: opacity 0.2s;
    outline: none;

    ::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      // width: 25px;
      // height: 25px;
      background: #04aa6d;
      cursor: pointer;
    }

    :hover {
      opacity: 1;
    }

    ::-moz-range-thumb {
      // width: 25px;
      // height: 25px;
      background: #04aa6d;
      cursor: pointer;
    }
  }

  &-inputanddate {
    display: flex;
    width: 100%;
    margin-top: 15px;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    .headinput-container {
      width: 50%;

      span {
        font-weight: 600;
        font-size: 12px;
      }

      input {
        background-color: white;
      }
    }

    &-dateset {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-top: 10px;

      span {
        font-weight: 600;
        font-size: 12px;
        font-family: Montserrat;
      }

      &-datedash {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;

        h6 {
          font-weight: 700;
          font-size: 25px;
          font-family: Montserrat;
        }

        input {
          width: 100%;
          background-color: white;
          border-radius: 5px;
          border: none;
          padding: 20px;
          text-align: start;
        }
      }
    }
  }

  &-textareainput {
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    span {
      font-weight: 600;
      font-size: 12px;
      font-family: Montserrat;
    }

    textarea {
      width: 100%;
      height: 8rem;
      // background-color: white;
      border-radius: 5px;
      border: none;
      padding: 20px;
      resize: none;
      text-align: start;
      background-color: white;
    }
  }

  //    &-wrapper{
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //     h1{
  //         font-family:Montserrat ;
  //     }

  //    }
  //    &-content{
  //     margin-top: 20px;
  //     &-inputdate {
  //         display: flex;

  //         flex-direction: column;
  //         gap:10px;

  //         &-divset {
  //             width: 85%;
  //             display: flex;
  //             align-items: center;
  //             justify-content: space-between;
  //         }

  //     }

  //     &-btn {
  //         margin-top: 15px;
  //         display: flex;
  //         width: 100%;
  //         justify-content: flex-end;
  //         align-items: flex-end;

  //         button {
  //             background-color: #eeb856;
  //             color: #000000;
  //             padding: 10px;
  //             font-size: 14px;
  //             font-family: Montserrat;
  //             font-weight: bold;
  //             padding-left: 2.5rem;
  //             padding-right: 2.5rem;
  //             border-radius: 5px;
  //             border: none;

  //           }
  //     }
  //    }
}

headinput-container-secondary {
  input {
    width: 100%;
    background-color: white;
    border-radius: 5px;
    border: none;
    padding: 20px;
    text-align: start;
  }
}

@media only screen and (max-width: 768px) and (min-width: 250px) {
  .generator-accordian-textfields {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
