.template-seven-container {
    width:90%;
    display: flex;
    height: fit-content;
    flex-direction: column;
    background-color: white;

    margin-top:3rem;
    &-header{
        height:15rem;
        width:100%;
        background-color:#F9E9D2;
        display: flex;
        align-items: center;
        justify-content: center;
        &-wrapper{ 
            width:85%;
            display: flex;
            gap:2rem;
            align-items: center;
           
            &-image{
                width:9rem;
                height:9rem;
                background-color:#FFF; 
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                width:8rem;
                height:8rem;
                border-radius:8rem;
            }
            }
            h1{
                font-size:2.5rem;
                font-weight: 200;
                word-break: break-word;
            }
        }
    }
    &-content{
        
    background-color: #fff;
    padding:3rem;
    display: flex;
    gap:2rem;
    &-left{
        width:30%;  
        &-heading{
            font-size: 1.3rem;
            text-align: center;
            border-bottom: 3px solid #CA9B51;
            border-top: 3px solid #CA9B51;
            padding-top:0.5rem;
            padding-bottom:0.5rem;
            word-break: break-word;
        }
        &-content{
            display: flex; 
            align-items: center;
            padding-top:1.5rem;
            flex-direction: column;
            &-icon {
              width:2.5rem;
              height:2.5rem;
              border-radius: 2.5rem;
              display: flex; 
              align-items: center;
              justify-content: center;

                border:2px solid #CA9B51;
            
                margin-bottom:0.2rem;
            }
            h3{
                
            font-size: 1rem;
            font-weight: 300;
            border-top: 3px solid #CA9B51;
            // border-bottom: 3px solid #CA9B51 20px;
          
            padding-top: 5px;
            padding-bottom: 1rem;
            width: 100%;
            text-align: center;
            // border-bottom-width:20 ;
            word-break: break-word;
            }
            p{
                    
            font-size: 0.8rem;
            font-weight: 300;
            color:grey;
            text-align: center;
            width: 80%;
            word-break: break-word;
            }
            span{
                height:0.6rem
            }
            &-divider{
                height:3px;
                background-color:#CA9B51;
                width:2rem;
            margin-bottom: 1rem;
            }
        }
    }
    &-right{
        width:70%;  
        &-heading{
            font-size: 1.3rem;
            text-align: center;
            border-bottom: 3px solid #CA9B51;
            border-top: 3px solid #CA9B51;
            padding-top:0.5rem;
            padding-bottom:0.5rem;
            word-break: break-word;
        }
        &-content{
            display: flex; 
            align-items: center;
            padding-top:1.5rem;
            flex-direction: column;
        
            h3{
                
            font-size: 1rem;
            font-weight: 300;
            word-break: break-word;
            }
            p{
                    
            font-size: 1rem;
            font-weight: 300;
            color:grey;
            text-align: center;
            width: 70%;
            word-break: break-word;
            }
            span{
                height:0.6rem
            }
        }
    }
    }
   &-spacing{
       height:0.5rem;
   }
}
@media only screen and (max-width: 429px) and (min-width: 280px) {
    .template-seven-container-header{
        height: 10rem;
    }
    .template-seven-container-content{
        padding: 1rem;
    }
    .template-seven-container-content-left-heading{
        font-size: 0.8rem;
    }
    .template-seven-container-content-right-heading {
        font-size: 0.8rem;

    }
}

@media only screen and (max-width: 1024px) and (min-width: 1024px) {
    .template-seven-container-content{padding: 1rem;
  }

  .template-seven-container-content-left-heading {
    font-size: 1rem;
    
  }
  .template-seven-container-content-right-heading{
    font-size: 1rem;
  }
}